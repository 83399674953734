

@keyframes loader-animation {
	0% {
			/* width: 15%; */
			width: 20%;
			left: 0%;
	}
	50% {
			width: 50%;
	}

	100% {
			left: 100%;
			/* width: 15%; */
			width: 20%;
			
	}
}
.loader {
	height: 4px;
	min-height: 4px;
	position: relative;
	width: 100%;
	overflow-x: clip;
	overflow-y: visible;
}
.loader .bar {
	position: absolute;
	transform: translateX(-50%);
	top: -2px;
	height: 8px;
	
	animation-name: loader-animation;
	animation-direction: alternate;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.splash {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	padding: 15vh 30vw;
	box-sizing: border-box;
}
@media (prefers-color-scheme: dark) {
	.splash {
		background: #00506D;
		color: #FFFFFF;
	}
	.loader .bar {
		background-color: #FFFFFF;
	}
	.loader {
		background: #AAC8EE;
	}
}

@media (prefers-color-scheme: light) {
	.splash {
		background: #e5e5e5;
		color: #A3AEBC;
	}
	.loader .bar {
		background-color: #00506D;
	}
	.loader {
		background: #FFFFFF;
	}
}
