@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import 'react-lazy-load-image-component/src/effects/blur.css';
* {
	margin: 0%;
	padding: 0%;
	scroll-behavior: smooth;
	box-sizing: border-box;
}
body {
	min-height: 100%;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: rgba(102, 115, 127, 0.5);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: rgba(98, 99, 100, 0.5);
	background-clip: padding-box;
} 
/* 
*/
.lazy-load-image-background {
	width: 100%;
}

:root {
	--ck-border-radius: 8px;
	--ck-color-toolbar-background: #fff !important;
	--ck-color-base-border: #d5dde5 !important;
}

.email-editor {
	height: 100%;
}
.email-editor .ck .ck-reset .ck-editor {
	height: 100%;
}
.email-editor .ck-editor__editable {
	min-height: 390px;
	height: 90%;
}
.email-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
	border: 0px;
}
.email-editor .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
	border: 0px;
	box-shadow: none;
}
.email-editor .ck .ck-editor__top {
	width: 100%;
	bottom: 0;
}
.email-editor .ck.ck-sticky-panel__content {
	border: 0px;

	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
}

/* .email-editor .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
	border: 0px !important;
} */
